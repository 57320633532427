<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="indigo lighten-5" flat>
        <BackButton />
        <v-toolbar-title>Картка акції</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="4" sm="6">
            <div class="mt-6">
              <div class="d-flex align-baseline custom-field">
                <span class="mr-1">ID акції:</span>
                <span class="font-weight-medium">{{ promotion.id }}</span>
              </div>
            </div>
            <div class="d-flex align-baseline custom-field">
              <span class="mr-1">Назва:</span>
              <v-text-field
                v-if="editCard"
                v-model="editedItem.title"
                :error-messages="inputErrors('title')"
                dense
                @blur="$v.editedItem.title.$touch()"
                @input="$v.editedItem.title.$touch()"
              />
              <span v-else class="font-weight-medium">{{ promotion.title }}</span>
            </div>
            <div v-if="currentTypeParams.start_at && currentTypeParams.expired_at">
              <div v-if="editCard" class="d-flex align-baseline custom-field">
                <span class="mr-1">Термін дії з:</span>
                <DatePicker :value="editedItem.start_at" @change="(date) => (editedItem.start_at = date)" />
                <span class="mr-1">Термін дії до:</span>
                <DatePicker :value="editedItem.expired_at" @change="(date) => (editedItem.expired_at = date)" />
              </div>
              <div v-else class="d-flex align-baseline custom-field">
                <span class="mr-1">Термін дії з:</span>
                <span class="font-weight-medium">{{ promotion.start_at | getShortDate }}</span>
                <span class="mx-1">Термін дії до:</span>
                <span class="font-weight-medium">{{ promotion.expired_at | getShortDate }}</span>
              </div>
            </div>
            <div class="d-flex align-baseline custom-field">
              <span class="mr-1">Тип акції:</span>
              <v-select
                v-if="editCard"
                v-model="editedItem.type"
                :items="companyPromotionsDictionary"
                dense
                disabled
                item-text="name"
                item-value="value"
              />
              <span v-else class="font-weight-medium">{{ getPromType(promotion.type) }}</span>
            </div>
            <div v-if="currentTypeParams.users" class="d-flex align-baseline custom-field">
              <span class="mr-1">Клієнти:</span>
              <PagingAutocomplete
                v-if="editCard"
                :disabled="!currentTypeParams.users.editable"
                :error-messages="inputErrors('users')"
                :options="clients"
                :options-meta="clientsMeta"
                :options-params="usersOptions"
                :value="editedItem.users"
                chips
                clearable
                deletable-chips
                item-value="user_id"
                multiple
                no-filter
                @blur="$v.editedItem.users.$touch()"
                @change="setUsers"
                @input="$v.editedItem.users.$touch()"
              >
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>mdi-information</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Залиште вибір порожнім для вказання всіх клієнтів</v-list-item-title>
                      <v-divider class="mt-2"></v-divider>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:item="{ item }">{{ item.first_name }} {{ item.last_name }}</template>
                <template v-slot:selection="{ item }">{{ item.first_name }} {{ item.last_name }}</template>
              </PagingAutocomplete>
              <span v-else class="font-weight-medium">{{
                promotion.users ? promotion.users.map((item) => `${item.first_name} ${item.last_name}`).join(', ') : 'Всі'
              }}</span>
            </div>
            <div v-if="currentTypeParams.service_points" class="d-flex align-baseline custom-field">
              <span class="mr-1">Торгові точки:</span>
              <PagingSelect
                v-if="editCard"
                :disabled="!currentTypeParams.service_points.editable"
                :options="servicePoints"
                :options-meta="servicePointsMeta"
                :options-params="servicePointsOptions"
                :value="editedItem.service_points"
                clearable
                deletable-chips
                dense
                item-text="name"
                item-value="id"
                multiple
                @change="setServicePoints"
              >
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>mdi-information</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Залиште вибір порожнім для вказання всіх торгових точок</v-list-item-title>
                      <v-divider class="mt-2"></v-divider>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </PagingSelect>
              <span v-else class="font-weight-medium">{{
                promotion.service_points ? promotion.service_points.map((item) => item.name).join(', ') : 'Всі'
              }}</span>
            </div>
            <div v-if="currentTypeParams.devices" class="d-flex align-baseline custom-field">
              <span class="mr-1">Типи автоматів:</span>
              <v-select
                v-if="editCard"
                v-model="editedItem.devices.types"
                :disabled="!currentTypeParams.devices.editable"
                :items="connectedDevicesTypesDictionary"
                chips
                clearable
                deletable-chips
                dense
                item-text="description"
                item-value="value"
                multiple
              >
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>mdi-information</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Залиште вибір порожнім для вказання всіх типів автоматів</v-list-item-title>
                      <v-divider class="mt-2"></v-divider>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-divider class="mt-2"></v-divider>
              </v-select>
              <span v-else class="font-weight-medium">{{
                promotion.devices && promotion.devices.types ? promotion.devices.types.join(', ') : 'Всі'
              }}</span>
            </div>
            <div v-if="currentTypeParams.devices" class="d-flex align-baseline custom-field">
              <span class="mr-1">Автомати:</span>
              <v-select
                v-if="editCard"
                v-model="editedItem.devices.ids"
                :disabled="!currentTypeParams.devices.editable"
                :items="devices"
                chips
                clearable
                deletable-chips
                dense
                item-text="label"
                item-value="value"
                multiple
              >
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>mdi-information</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Залиште вибір порожнім для вказання всіх автоматів</v-list-item-title>
                      <v-divider class="mt-2"></v-divider>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item }">
                  <v-chip class="ma-1" close small>
                    {{ item.label }}
                  </v-chip>
                </template>
              </v-select>
              <span v-else class="font-weight-medium">{{
                promotion.devices && promotion.devices.ids ? promotion.devices.ids.join(', ') : 'Всі'
              }}</span>
            </div>
            <div v-if="currentTypeParams.value_type" class="d-flex align-baseline custom-field">
              <span class="mr-1">Вид бонуса:</span>
              <v-select
                v-if="editCard"
                v-model="editedItem.value_type"
                :disabled="!currentTypeParams.value_type.editable"
                :items="valueTypes"
                dense
                item-text="value"
                item-value="id"
              />
              <span v-else class="font-weight-medium">{{ getValueType(promotion.value_type) }}</span>
            </div>
            <div v-if="currentTypeParams.amount" class="d-flex align-baseline custom-field">
              <span class="mr-1">Розмір бонуса:</span>
              <v-text-field
                v-if="editCard"
                v-model="editedItem.amount"
                :disabled="!currentTypeParams.amount.editable"
                :error-messages="inputErrors('amount')"
                :suffix="getValueType(editedItem.value_type)"
                dense
                type="number"
                @blur="$v.editedItem.amount.$touch()"
                @input="$v.editedItem.amount.$touch()"
              />
              <span v-else class="font-weight-medium">{{ promotion.amount }}</span>
            </div>
            <div v-if="currentTypeParams.description" class="d-flex align-baseline custom-field">
              <span class="mr-1">Опис:</span>
              <v-textarea
                v-if="editCard"
                v-model="editedItem.description"
                :disabled="!currentTypeParams.description.editable"
                :error-messages="inputErrors('description')"
                outlined
                @blur="$v.editedItem.description.$touch()"
                @input="$v.editedItem.description.$touch()"
              />
              <span v-else class="font-weight-medium">{{ promotion.description }}</span>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid>
        <div class="d-flex justify-end">
          <v-btn class="mr-2" small @click="editClick">
            {{ !editCard ? 'Редагувати' : 'Повернутись' }}
          </v-btn>
          <v-btn v-show="editCard" color="primary" small @click="saveClick">Зберегти</v-btn>
        </div>
      </v-container>
    </v-card>

    <ConfirmActionModal :showModal="showModal" @closeModal="showModal = false">
      <template v-slot:title>Зберегти зміни?</template>
      <template v-slot:text>Дані будуть оновлені</template>
      <template v-slot:buttons>
        <v-btn color="primary" text @click="save">Так</v-btn>
        <v-btn text @click="showModal = false">Нi</v-btn>
      </template>
    </ConfirmActionModal>
  </v-container>
</template>

<script>
import BackButton from '@/components/common/BackButton'
import { mapActions, mapGetters, mapState } from 'vuex'
import CloneDeep from 'lodash/cloneDeep'
import { PROMOTION_SCHEMA } from '@/const/apiSchemas'
import DatePicker from '@/components/common/DatePicker'
import PagingSelect from '@/components/common/PagingSelect'
import manageFiltersOptions from '@/mixins/manageFiltersOptions'
import ConfirmActionModal from '@/components/dialogs/ConfirmActionModal.vue'
import cloneDeep from '@/mixins/cloneDeep'
import convertDate from '@/mixins/convertDate'
import { validationMixin } from 'vuelidate'
import { required, minValue } from 'vuelidate/lib/validators'
import PagingAutocomplete from '@/components/common/PagingAutocomplete.vue'

export default {
  name: 'PromotionCard',
  components: { PagingAutocomplete, ConfirmActionModal, PagingSelect, DatePicker, BackButton },

  mixins: [validationMixin, manageFiltersOptions, cloneDeep, convertDate],

  data() {
    return {
      isLoaded: false,
      editCard: false,
      showModal: false,
      editedItem: null,
      usersOptions: null,
      servicePointsOptions: null,
      currentTypeParams: {},
    }
  },

  validations() {
    return {
      editedItem: {
        title: { required },
        description: { required },
        users: { required },
        amount: { required, minValue: minValue(1) },
      },
    }
  },

  created() {
    this.initialize()
  },

  computed: {
    ...mapState('promotions', ['promotion', 'promotionsMeta', 'promotionTypes', 'valueTypes', 'promotionParams']),
    ...mapState('dictionaries', ['companyPromotionsDictionary', 'connectedDevicesTypesDictionary']),
    ...mapState('servicePoints', ['servicePoints', 'servicePointsMeta']),
    ...mapState('companies', ['filter', 'isFilterLoaded']),
    ...mapState('users', ['clients', 'clientsMeta']),
    ...mapGetters('companies', ['getBalanceHolderFilter', 'getServicePointFilter', 'getTerminalFilter', 'getDeviceFilter']),

    currentCompanyId() {
      return this.$route.params.id
    },

    promotionId() {
      return this.$route.params.promotionId
    },
  },

  methods: {
    ...mapActions('promotions', ['loadSelectedPromotion', 'updateSelectedPromotion', 'deleteSelectedPromotion']),
    ...mapActions('servicePoints', ['loadServicePoints']),
    ...mapActions('companies', ['loadFilter']),
    ...mapActions('users', ['loadClients']),

    async initialize() {
      this.editedItem = CloneDeep(PROMOTION_SCHEMA)
      if (!this.promotionId) return
      const defaultPayload = {
        company: this.currentCompanyId,
        forSearch: 1,
      }
      this.usersOptions = {
        loadingFunction: this.loadClients,
        payload: { ...defaultPayload },
      }
      this.servicePointsOptions = {
        loadingFunction: this.loadServicePoints,
        payload: { ...defaultPayload },
      }
      if (this.currentCompanyId !== 'undefined') {
        const payload = {
          companyId: this.currentCompanyId,
          promotionId: this.promotionId,
        }
        await this.loadSelectedPromotion(payload)
        this.editedItem = this.cloneObjectDeep(this.promotion)
        const params = this.promotionParams.find((item) => item.type === this.promotion.type)
        if (!params) return
        this.currentTypeParams = params
        this.isLoaded = true
      } else {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
      }
    },

    getPromType(type) {
      const prom = this.companyPromotionsDictionary.find((item) => item.value === type)
      return prom?.name || null
    },

    getValueType(type) {
      const val = this.valueTypes.find((item) => item.id === type)
      return val?.value || null
    },

    setUsers(users) {
      this.editedItem.users = users
    },

    setServicePoints(points) {
      this.editedItem.service_points = points
      this.selectedServicePoints = points.map((item) => item.id)
      this.selectedTerminals = [...this.terminals.map((item) => item.value)]
      this.selectedDevicesTypes = [...this.deviceTypes.map((item) => item.name)]
    },

    inputErrors(fieldName) {
      const errors = []
      if (!this.$v.editedItem[fieldName].$dirty) return errors
      !this.$v.editedItem[fieldName].required && errors.push('Це поле обов"язкове')
      this.$v.editedItem[fieldName].minValue === false &&
        errors.push(`Введіть значення від ${this.$v.editedItem[fieldName].$params.minValue.min}`)
      return errors
    },

    editClick() {
      this.editedItem = this.cloneObjectDeep(this.promotion)
      this.editCard = !this.editCard
    },

    saveClick() {
      this.$v.editedItem.$touch()
      if (this.$v.$anyError) return
      this.showModal = true
    },

    async save() {
      const payload = { ...this.editedItem }
      delete payload.id
      delete payload.users
      delete payload.service_points
      payload.amount = +this.editedItem.amount
      payload.user_ids = [...this.editedItem.users.map((item) => item.id)]
      payload.service_point_ids = [...this.selectedServicePoints]
      try {
        await this.updateSelectedPromotion({
          companyId: this.currentCompanyId,
          id: this.promotionId,
          updatedPromotion: payload,
        })
      } finally {
        this.showModal = false
        this.editCard = false
      }
    },
  },
}
</script>
